import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import GenericRoutes from "./Routes";
import { IoIosCall } from "react-icons/io";
import * as Component from "./components/common";

function App() {
  //Smooth Scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  //PopUp Box
  useEffect(() => {
    const popupTimeout = setTimeout(() => {
      setShowPopup(true);
    }, 300000);

    return () => {
      clearTimeout(popupTimeout);
    };
  }, [showPopup]);

  return (
    <div className="relative">
      <Component.Header />
      {showPopup && <Component.PopUp onClose={handleClosePopup} />}
      <GenericRoutes />
      <Component.Footer />
      <div className="fixed bottom-5 right-5">
        <div className="flex flex-col gap-4">
          <a href="tel:+917356252888">
            <IoIosCall
              color="white"
              size="40px"
              className="bg-blue-600 rounded-full p-2"
            />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=%2B917356252888&text&type=phone_number&app_absent=0"
            target="_blank" rel="noreferrer"
          >
            <img
              src="/image/whatsapp.png"
              alt="whatsapp icon"
              className="w-11"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
